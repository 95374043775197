import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~5],
		"/dashboard": [~6,[2],[3]],
		"/dashboard/logout": [~8,[2],[3]],
		"/dashboard/pdfs": [~9,[2],[3]],
		"/dashboard/profile": [~10,[2],[3]],
		"/dashboard/profile/admin": [~11,[2],[3]],
		"/dashboard/profile/settings": [~12,[2],[3]],
		"/dashboard/profile/settings/changepass": [~13,[2],[3]],
		"/dashboard/profile/terms": [~14,[2],[3]],
		"/dashboard/rdocs": [~15,[2],[3]],
		"/dashboard/report": [~16,[2],[3]],
		"/dashboard/report/editor": [~17,[2],[3]],
		"/dashboard/report/editor/[date]/[costcenter]": [~18,[2],[3]],
		"/dashboard/report/upload": [~19,[2],[3]],
		"/dashboard/report/upload/[filekey]": [~20,[2],[3]],
		"/dashboard/[year]": [~7,[2],[3]],
		"/landing": [21,[4]],
		"/landing/about-us": [22,[4]],
		"/landing/blog/[blogId]": [~23,[4]],
		"/landing/coming-soon": [24,[4]],
		"/landing/contact-us": [~25,[4]],
		"/landing/login": [~26,[4]],
		"/landing/pricing": [27,[4]],
		"/landing/request-demo": [28,[4]],
		"/landing/services": [29,[4]],
		"/landing/thanks": [30,[4]],
		"/sentry-example": [31],
		"/verify/[requestId]": [~32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';